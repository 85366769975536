.myProcessTitle {
  box-sizing: border-box;
  margin: 0;
  color: #fff;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 0.5;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
  padding: 16px 24px;
  background-color: #fff;
  border: 1px solid rgb(235, 237, 240);
  border-radius: 5px;
  // background: #54acfe;
  background: rgb(103, 181, 255);
  background: linear-gradient(90deg,
      rgba(103, 181, 255, 1) 0%,
      rgba(84, 172, 254, 1) 40%,
      rgba(0, 212, 255, 1) 100%);
}

.tableTravel {
  margin-top: 10px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  text-align: center;
  padding: 10px;
}

.toolBar {
  margin-top: 8px;
}

.tableColumm {
  border-color: rgb(190, 0, 0);
  border-radius: 6px;
}

.statusRow {
  display: flex;
  flex-direction: row;
  align-items: center;
}